// /component/path/node/Node.js
import * as THREE from 'three';
import _ from 'lodash';

class Node {
    constructor(attributes) {
        // Checks if position is defined
        // Checks if position is instance of THREE.Vector3
        // Assigns a THREE.Vector3(0, 0, 0) if any of the conditions above fails
        this.position =
            attributes?.position instanceof THREE.Vector3
                ? attributes.position
                : new THREE.Vector3(0, 0, 0);

        // Checks if isCheckpoint is defined
        // Checks if isCheckpoint is instance of THREE.Vector3
        // Assigns `false` if any of the conditions above fails
        this.isCheckpoint =
            typeof attributes?.isCheckpoint === 'boolean' ? attributes.isCheckpoint : false;

        this.mesh = this.createBoxMesh();

        // Node Label
        this.labelTitle = typeof attributes?.labelTitle === 'string' ? attributes.labelTitle : '';
        this.labelMessage =
            typeof attributes?.labelMessage === 'string' ? attributes.labelMessage : '';
        this.labelPosition =
            attributes?.labelPosition instanceof THREE.Vector3
                ? attributes.labelPosition
                : new THREE.Vector3(0, 0, 0);
        this.titleStyles =
            typeof attributes?.titleStyles === 'object' ? attributes.titleStyles : {};
        this.messageStyles =
            typeof attributes?.messageStyles === 'object' ? attributes.messageStyles : {};
        this.containerStyles =
            typeof attributes?.containerStyles === 'object' ? attributes.containerStyles : {};
        this.labelViewDistance =
            typeof attributes?.labelViewDistance === 'number' ? attributes.labelViewDistance : 1000;

        // Target rotation of an object once it reaches this node
        this.targetQuaternion =
            attributes?.targetQuaternion instanceof THREE.Quaternion
                ? attributes.targetQuaternion
                : new THREE.Quaternion(0, 0, 0, 0);

        this.renderText();
        this.renderScrollerDot();
    }

    createBoxMesh() {
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({
            color: this.getMeshColor(),
        });

        // Create the mesh using the geometry and material
        const mesh = new THREE.Mesh(geometry, material);
        const scaleAmount = this.getMeshScale();
        mesh.scale.set(scaleAmount, scaleAmount, scaleAmount);
        mesh.position.copy(this.position);

        return mesh;
    }

    renderText() {
        // Check if text needs to be rendered in the first place
        if (!this.shouldRenderText()) {
            return;
        }

        // Add Node's Text directly to DOM
        this.container = document.createElement('div');
        this.container.className = 'node-label-container';

        _.forEach(this.containerStyles, (value, key) => {
            this.container.style.setProperty(key, value);
        });

        if (this.labelTitle || this.labelTitle === '') {
            this.title = document.createElement('div');
            this.title.className = 'node-label-title';
            this.title.textContent = this.labelTitle;
            this.container.appendChild(this.title);

            _.forEach(this.titleStyles, (value, key) => {
                this.title.style.setProperty(key, value);
            });
        }

        if (this.labelMessage || this.labelMessage === '') {
            this.message = document.createElement('div');
            this.message.className = 'node-label-message';
            this.message.textContent = this.labelMessage;
            this.container.appendChild(this.message);

            _.forEach(this.messageStyles, (value, key) => {
                this.message.style.setProperty(key, value);
            });
        }

        let mainElement = document.getElementsByTagName('main')[0];
        mainElement.appendChild(this.container);
    }

    renderScrollerDot() {
        if (!this.isCheckpoint) {
            return;
        }

        // Add Node's Text directly to DOM
        this.dotsContainer = document.getElementById('dotsContainer');

        this.dotSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this.dotSvg.setAttribute('class', 'dot-svg');
        this.dotSvg.setAttribute('viewBox', '0 0 100 100');
        this.dotSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        this.dotSvg.setAttribute('width', '12');
        this.dotSvg.setAttribute('height', '12');

        this.dotCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        this.dotCircle.setAttribute('class', 'dot-circle');
        this.dotCircle.setAttribute('cx', 50);
        this.dotCircle.setAttribute('cy', 50);
        this.dotCircle.setAttribute('r', 36);

        this.dotSvg.appendChild(this.dotCircle);
        this.dotsContainer.appendChild(this.dotSvg);
    }

    update(attributes) {
        this.position =
            attributes?.position instanceof THREE.Vector3
                ? attributes.position
                : new THREE.Vector3(0, 0, 0);
        this.isCheckpoint =
            typeof attributes?.isCheckpoint === 'boolean' ? attributes.isCheckpoint : false;
        this.mesh.position.copy(this.position);
    }

    updateLabelOpacity(viewerPosition) {
        if (this.labelTitle === '' && this.labelMessage === '') {
            return;
        }

        const nodeWorldPosition = new THREE.Vector3().copy(this.mesh.position);
        const minSquaredDistance = 550;
        const maxSquaredDistance = this.labelViewDistance;
        const distanceSquared = nodeWorldPosition.distanceToSquared(viewerPosition);

        let opacityAmount =
            (1 -
                Math.max(
                    0,
                    Math.min(
                        1,
                        (distanceSquared - minSquaredDistance) /
                            (maxSquaredDistance - minSquaredDistance),
                    ),
                )) *
            100;
        this.container.style.opacity = opacityAmount + '%';
    }

    updateScrollerVisuals(viewerPosition) {
        if (!this.dotSvg) {
            return;
        }

        const nodeWorldPosition = new THREE.Vector3().copy(this.mesh.position);
        const distanceSquared = nodeWorldPosition.distanceToSquared(viewerPosition);

        if (distanceSquared > 200) {
            this.dotSvg.setAttribute('class', 'dot-svg');
            this.dotCircle.setAttribute('class', 'dot-circle');
            return;
        }

        this.dotSvg.setAttribute('class', 'dot-svg selected-state');
        this.dotCircle.setAttribute('class', 'dot-circle selected-state');
    }

    getMeshScale() {
        return this.isCheckpoint ? 1 : 0.5;
    }

    getMeshColor() {
        return this.isCheckpoint ? 0x3bf73b : 0xdc143c;
    }

    shouldRenderText() {
        if (this.labelTitle === '' && this.labelMessage === '') return false;
        if (this.labelTitle === null && this.labelMessage === null) return false;
        if (this.labelTitle === undefined && this.labelMessage === undefined) return false;

        return true;
    }
}

export { Node };
