// /component/helicopter/setup.js
import {
    AnimationMixer,
    PointLight,
    SpotLight,
    PointLightHelper,
    SpotLightHelper,
    MathUtils,
} from 'three';

export async function setup(scene, loadedItems) {
    let helicopter = loadedItems['models']['helicopter'];
    if (!helicopter) {
        throw 'Model Setup Phase Error';
    }

    helicopter.name = 'Helicopter';
    helicopter.scale.set(0.01, 0.01, 0.01);
    helicopter.rotation.set(0, MathUtils.degToRad(180), 0);
    helicopter.position.set(-13.5, 400, 750);

    // Spotlights
    const peachSpotLight = new SpotLight(0xf2d9c7); // peach color
    peachSpotLight.name = 'Peach Light';
    peachSpotLight.scale.set(100, 100, 100); // Reset the scale of the spotlight as it will be skewed by Helicopter
    peachSpotLight.intensity = 16;
    peachSpotLight.distance = 4.4;
    peachSpotLight.angle = 0.67;
    peachSpotLight.penumbra = 1;
    peachSpotLight.decay = 3;
    peachSpotLight.visible = false;
    peachSpotLight.position.set(112.72, 19.05, -467.31);
    peachSpotLight.target.position.set(0, -1000, 0);
    helicopter.add(peachSpotLight.target);
    helicopter.add(peachSpotLight);

    // Helper
    const peachSpotLightHelper = new SpotLightHelper(peachSpotLight);
    peachSpotLight.helper = peachSpotLightHelper;
    // scene.add(peachSpotLightHelper);

    // const cyanSpotLight = new SpotLight(0x00ffff); // cyan color
    const cyanSpotLight = new SpotLight(0x00ced1); // cyan color
    cyanSpotLight.name = 'Cyan Light';
    cyanSpotLight.scale.set(100, 100, 100); // Reset the scale of the spotlight as it will be skewed by Helicopter
    cyanSpotLight.intensity = 5;
    cyanSpotLight.distance = 3.2;
    cyanSpotLight.angle = 0.81;
    cyanSpotLight.penumbra = 1;
    cyanSpotLight.decay = 1.8;
    cyanSpotLight.visible = false;
    cyanSpotLight.position.set(-273.4, -247.29, -240.61);
    cyanSpotLight.target.position.set(633.09, -9.58, -350);
    helicopter.add(cyanSpotLight.target);
    helicopter.add(cyanSpotLight);

    // Helper
    const cyanSpotLightHelper = new SpotLightHelper(cyanSpotLight);
    cyanSpotLight.helper = cyanSpotLightHelper;
    // scene.add(cyanSpotLightHelper);

    const peachPointLight = new PointLight(0xbd7800, 200, 100, 4);
    peachPointLight.position.set(300, -150, -250);
    // helicopter.add(peachPointLight);

    const sphereSize = 35;
    const peachPointLightHelper = new PointLightHelper(peachPointLight, sphereSize);
    // scene.add(peachPointLightHelper);

    const cyanPointLight = new PointLight(0x0011ff, 200, 100, 4);
    cyanPointLight.position.set(-300, -150, -250);
    // helicopter.add(cyanPointLight);

    const cyanPointLightHelper = new PointLightHelper(cyanPointLight, sphereSize);
    // scene.add(cyanPointLightHelper);

    // Animations
    const mixer = new AnimationMixer(helicopter);
    helicopter.mixer = mixer;
    const action = mixer.clipAction(helicopter.animations[0]);
    action.play();

    scene.add(helicopter);
    return helicopter;
}

export default setup;
