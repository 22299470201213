// /component/city/setup.js
export async function setup(scene, loadedItems) {
    // let city = loadedItems['models']['city'];
    // city.position.y = -1250;
    // city.position.z = -12500;
    // scene.add(city);
    // let city2 = city.clone();
    // city2.position.y = -1250;
    // city2.position.z = 17500;
    // scene.add(city2);
    // let city3 = city.clone();
    // city3.position.y = -1250;
    // city3.position.z = 35000;
    // scene.add(city3);
    // console.log(scene);
    // return city;
}

export default setup;
