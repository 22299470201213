// Get the audio element tag
const audio = document.getElementById('audio');

// Create an audio context
const ctx = new AudioContext();

// Create an audio source
const audioSource = ctx.createMediaElementSource(audio);

// Create an audio analyzer
const analayzer = ctx.createAnalyser();

// Print the analyze frequencies
const frequencyData = new Uint8Array(analayzer.frequencyBinCount);
analayzer.getByteFrequencyData(frequencyData);

const numberOfBars = 8;
const barHeightFactor = 0.1;
const maximumBarHeight = 21;

const audioVisualizer = document.getElementById('audioVisualizer');
const audioStateElement = document.getElementById('audioState');
const audioVisualizerContainer = document.getElementById('audioVisualizerContainer');
const audioButtonContainer = document.getElementById('audioButtonContainer');

// Get the visualizer container
const visualizerContainer = document.getElementById('audioVisualizerBarContainer');

let layoutStyle = 'desktop';
if (window.innerWidth <= 640) {
    layoutStyle = 'mobile';
}

desktopSetup();
mobileSetup();

audioSource.connect(analayzer);
audioSource.connect(ctx.destination);

listenerSetup();

function desktopSetup() {
    // Hide Mobile Audio Component
    audioButtonContainer.className = 'audioButtonContainer hidden';

    // Create a set of pre-defined bars
    for (let i = 0; i < numberOfBars; i++) {
        const bar = document.createElement('div');
        bar.setAttribute('id', 'bar' + i);
        bar.setAttribute('class', 'audioVisualizerContainer visualizer__bar');
        visualizerContainer.appendChild(bar);
    }
}

function mobileSetup() {
    // Hide Desktop Audio Component
    audioVisualizerContainer.className = 'audioVisualizerContainer hidden';
}

function listenerSetup() {
    // Create listener events
    audioVisualizer.addEventListener('click', () => {
        if (layoutStyle === 'mobile') {
            return;
        }

        if (ctx.state === 'suspended') {
            ctx.resume().then(() => {
                document.getElementById('audio').play();
            });
        } else {
            ctx.suspend();
        }
    });

    audioButtonContainer.addEventListener('click', () => {
        if (layoutStyle === 'desktop') {
            return;
        }

        if (ctx.state === 'suspended') {
            ctx.resume().then(() => {
                document.getElementById('audio').play();
            });
        } else {
            ctx.suspend();
        }
    });
}

function updateAudioComponent() {
    if (layoutStyle === 'desktop') {
        // Hide mobile audio component if not already hidden
        if (audioButtonContainer.className !== 'audioButtonContainer hidden') {
            audioButtonContainer.className = 'audioVisualizerContainer hidden';
        }

        // Show desktop audio component if not already shown
        if (audioVisualizerContainer.className !== 'audioVisualizerContainer') {
            audioVisualizerContainer.className = 'audioVisualizerContainer';
        }

        // Audio State Text
        if (ctx.state === 'suspended') {
            audioStateElement.innerText = 'Sound Off';
        } else {
            audioStateElement.innerText = 'Sound On';
        }

        updateAudioVisualizer();
    } else if (layoutStyle === 'mobile') {
        // Hide desktop audio component if not already hidden
        if (audioVisualizerContainer.className !== 'audioButtonContainer hidden') {
            audioVisualizerContainer.className = 'audioButtonContainer hidden';
        }

        // Show mobile audio component if not already shown
        if (audioButtonContainer.className !== 'audioVisualizerContainer') {
            audioButtonContainer.className = 'audioVisualizerContainer';
        }

        // Audio State Text
        if (ctx.state === 'suspended') {
            const audioButtonSoundOn = document.getElementById('audioButtonSoundOn');
            const audioButtonSoundOff = document.getElementById('audioButtonSoundOff');
            audioButtonSoundOn.style.display = 'block';
            audioButtonSoundOff.style.display = 'none';
        } else {
            const audioButtonSoundOn = document.getElementById('audioButtonSoundOn');
            const audioButtonSoundOff = document.getElementById('audioButtonSoundOff');
            audioButtonSoundOn.style.display = 'none';
            audioButtonSoundOff.style.display = 'block';
        }

        updateMobileAudio();
    }

    if (window.innerWidth > 640) {
        layoutStyle = 'desktop';
    } else if (window.innerWidth <= 640) {
        layoutStyle = 'mobile';
    }
}

// Audio Visualizer
function updateAudioVisualizer() {
    // Update our frequency data array with the latest frequency data
    analayzer.getByteFrequencyData(frequencyData);

    for (let i = 0; i < numberOfBars; i++) {
        // Since the frequency data array is 1024 in length, we don't want to fetch
        // the first numberOfBars of values, but try and grab frequencies over the whole spectrum
        const index = (i + 10) * 2;
        // fd is a frequency value between 0 and 255
        const fd = ctx.state === 'suspended' ? 0 : frequencyData[index];

        // Fetch the bar DIV element
        const bar = document.querySelector('#bar' + i);
        if (!bar) {
            continue;
        }

        // If fd is undefined, default to 0, then make sure fd is at least 4
        // This will make make a quiet frequency at least 4px high for visual effects
        let barHeight = Math.max(6, fd * barHeightFactor || 0);
        barHeight = Math.min(barHeight, maximumBarHeight);
        bar.style.height = barHeight + 'px';
    }
}

function updateMobileAudio() {}

// Setup Audio Context
let hasFocusedOnce = false;
document.body.addEventListener(
    'click',
    () => {
        if (hasFocusedOnce) {
            return;
        }

        ctx.resume().then(() => {
            document.getElementById('audio').play();
            hasFocusedOnce = true;
        });
    },
    true,
);

export default { updateAudioComponent };
