// /component/helicopter/setup.js
import { AnimationMixer, Vector3 } from 'three';

export async function setup(scene, loadedItems) {
    // console.log('hit setting up scene.json');
    // let helicopter = loadedItems['models']['helicopter'];
    // if (!helicopter) {
    //     throw 'Model Setup Phase Error';
    // }
    // const mixer = new AnimationMixer(helicopter);
    // helicopter.mixer = mixer;
    // const action = mixer.clipAction(helicopter.animations[0]);
    // action.play();
    // helicopter.scale.set(1, 1, 1);
    // helicopter.position.set(0, 10000, 0);
    // scene.add(helicopter);
    // return helicopter;
}

export default setup;
