import * as THREE from 'three';
import _ from 'lodash';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { TransformControls } from 'three/addons/controls/TransformControls.js';
import WebGL from 'three/addons/capabilities/WebGL.js';
import VirtualScroll from 'virtual-scroll';
import loadModels from './components/model-loader';
import loadGui from './components/dev-helpers/gui';
import setup from './components/setup';
import { CSS2DRenderer } from 'three/addons/renderers/CSS2DRenderer.js';
import { CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';
import audio from './components/helpers/audio';

// Game Setup
const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.shadowMap.enabled = true;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1.5;
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0x404040, 1);

const labelRenderer = new CSS2DRenderer();
labelRenderer.setSize(window.innerWidth, window.innerHeight);
labelRenderer.domElement.style.position = 'absolute';
labelRenderer.domElement.style.top = '0px';
labelRenderer.domElement.addEventListener('pointerdown', onPointerDown);

let scene = new THREE.Scene();
// let scenePath = '/scene/scene.json';
let scenePath = '/scene/city_path.json';
const loadedScene = await new Promise((resolve) =>
    new THREE.ObjectLoader().load(
        scenePath,
        (loadedJson) => {
            resolve(loadedJson);
        },
        undefined,
        (error) => {
            console.error(error);
            resolve(null);
        },
    ),
);
scene = loadedScene;
scene.background = new THREE.Color(0x001f7a);
scene.fog = new THREE.FogExp2(0x001f7a, 0.003);
scene.isMobile = !!(window.innerWidth < 600);

// Camera
let camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 400);
camera.position.copy(new THREE.Vector3(-200, 300, -200.5));
scene.add(camera);

let orbitCamera = new OrbitControls(camera, labelRenderer.domElement);
orbitCamera.enableDamping = false;
orbitCamera.enabled = false;
camera.orbitControls = orbitCamera;

// Resize
window.addEventListener('resize', onWindowResize, false);
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.setSize(window.innerWidth, window.innerHeight);

    scene.isMobile = !!(window.innerWidth < 600);
}

const animationClock = new THREE.Clock();
const gameClock = new THREE.Clock();
let helicopter;
let path;

// Global object to store loaded items
const loadedItems = {
    models: {},
    materials: {},
};

// Call the loadModels function
const { loadedModels, loadedMaterials } = await loadModels();

// Store loaded models and materials in the global object
loadedItems.models = loadedModels;
loadedItems.materials = loadedMaterials;

await setup(scene, loadedItems);
helicopter = loadedItems.models.helicopter;
path = loadedItems.path;
scene.path = path;

// 7 - 10 Blades
const helicopterBlades = {
    a: helicopter.getObjectByName('helicopter036'),
    b: helicopter.getObjectByName('helicopter044'),
    c: helicopter.getObjectByName('helicopter041'),
    d: helicopter.getObjectByName('helicopter042'),
};

const helicopterBladeColors = {
    a: new THREE.Color(0x808080),
    b: new THREE.Color(0x949494),
    c: new THREE.Color(0xbebebe),
    d: new THREE.Color(0xebecf0),
};

_.each(helicopterBlades, (blade, key) => {
    blade.material.color = new THREE.Color(0x808080);
});

// Scroller Controls
const scroller = new VirtualScroll();
// scene.scrollYThreshold = 25;
// scene.autoScrollThreshold = 500;
// scene.autoScroll = false;
scene.scrollYSpeed = 0;
scene.noScrollDuration = 0;
scene.scrollSpeedFactor = 0.00005;
scene.scrollDirection = 0;
scene.hideDebugTools = false;
let snappingToNode = false;
scene.scrollerGif = document.getElementById('scrollerGif');
scene.dotsContainer = document.getElementById('dotsContainer');
scroller.on((event) => {
    if (
        !snappingToNode &&
        (event.originalEvent.type === 'wheel' || event.originalEvent.type === 'touchmove')
    ) {
        // Scene seemed smoother without the scroll threshold. Hence removing it for the time being.
        // if (Math.abs(event.deltaY) <= scene.scrollYThreshold) {
        //     scene.scrollYSpeed = 0;
        //     return;
        // }

        scene.scrollYSpeed = event.deltaY;
        scene.scrollYSpeed *= scene.scrollSpeedFactor;

        scene.scrollYSpeed = THREE.MathUtils.clamp(scene.scrollYSpeed, -0.001, 0.001);
    }
});

// Keyboard Controls
camera.shouldLerpCameraToHelicopter = true;
helicopter.shouldLerpHelicopterToTargetPosition = false;
scene.shouldScrollToMoveHelicopter = true;
// document.addEventListener('keydown', onDocumentKeyDown, false);
function onDocumentKeyDown(keydownEvent) {
    console.log(keydownEvent);
    if (keydownEvent.code === 'KeyA' && keydownEvent.keyCode === 65) {
        scene.hideDebugTools = !scene.hideDebugTools;
    }
    if (keydownEvent.code === 'KeyV' && keydownEvent.keyCode === 86) {
        if (path) {
            path.nodes.map((node) => {
                console.log(node.position);
            });
        }
    }
    if (keydownEvent.code === 'KeyB' && keydownEvent.keyCode === 66) {
        if (path) {
            // Get the position of the last node
            const lastNode = path.nodes.slice(-1)[0];
            const newPosition = new THREE.Vector3().copy(lastNode.position);
            newPosition.x -= 750;
            newPosition.z -= 750;

            path.addNode({
                position: newPosition,
                isCheckpoint: true,
                labelMessage: 'This is a new node',
                labelPosition: new THREE.Vector3(0, 0, 0),
                labelStyle: {},
            });
            path.drawLines();
        }
    }
    if (keydownEvent.code === 'KeyS' && keydownEvent.keyCode === 83) {
        if (!path) {
            return;
        }

        let nodeDistances = [];
        _.forEach(path.nodes, (node, index) => {
            const distanceToNode = helicopterTargetPosition.distanceToSquared(node.position);
            nodeDistances.push(distanceToNode);
        });
        const minValue = _.min(nodeDistances);
        const closestNode = nodeDistances.findIndex((value) => value === minValue);
    }

    if (keydownEvent.code === 'Space' && keydownEvent.keyCode === 32) {
        helicopter.shouldLerpHelicopterToTargetPosition =
            !helicopter.shouldLerpHelicopterToTargetPosition;
        orbitCamera.enabled = !orbitCamera.enabled;
    }
}

// Mouse Controls
const ACTION_SELECT = 1,
    ACTION_NONE = 0;
let mouse = new THREE.Vector2();
let raycaster = new THREE.Raycaster();
let mouseAction;
let transformControl = new TransformControls(camera, labelRenderer.domElement);
transformControl.name = 'Transform Controls';
transformControl.mode = 'translate';
transformControl.translationSnap = 0.5;
transformControl.addEventListener('dragging-changed', function (event) {
    // Will only run in debug mode
    if (scene.hideDebugTools) {
        return;
    }

    orbitCamera.enabled = !event.value;
    if (!event.value) {
        let newPathNodesAttributes = [];
        path.nodes.map((node) => {
            newPathNodesAttributes.push({
                position: node.mesh.position,
                isCheckpoint: node.isCheckpoint,
            });
        });
        path.updatePath(newPathNodesAttributes);
        // transformControl.detach();
    }
});

function onPointerDown(event) {
    // Will only run in debug mode
    if (scene.hideDebugTools) {
        return;
    }

    mouseAction = ACTION_SELECT;
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    if (mouseAction === ACTION_SELECT) {
        raycaster.setFromCamera(mouse, camera);
        mouseAction = 0;

        const intersects = raycaster.intersectObjects(path.line.children, false);
        if (intersects.length) {
            const target = intersects[0].object;
            transformControl.attach(target);
            scene.add(transformControl);
        }
    }
}

// Lerp factor for smooth movement
camera.lerpFactor = 0.05; // Adjust the value as needed for the desired speed of movement

// Function to update the camera's position and rotation towards the helicopter
// Define the idealOffset as an object within the camera so that it is easily controlled by the GUI
helicopter.cameraAngleOffsets = {
    bottomOffset: new THREE.Vector3(-8, -5, -13),
    leftOffset: new THREE.Vector3(2, 4, -15),
};
camera.idealOffset = helicopter.cameraAngleOffsets.bottomOffset;
camera.idealLookAt = new THREE.Vector3(0, -1, 0);
function lerpCameraToHelicopter() {
    if (!camera && !helicopter) {
        return;
    }

    const idealOffset = camera.idealOffset.clone();
    idealOffset.applyQuaternion(helicopter.quaternion);
    idealOffset.add(helicopter.position);

    const idealLookAt = camera.idealLookAt.clone();
    idealLookAt.applyQuaternion(helicopter.quaternion);
    idealLookAt.add(helicopter.position);

    camera.position.lerp(idealOffset, camera.lerpFactor);
    camera.lookAt(idealLookAt);
}

let helicopterTargetPosition = new THREE.Vector3();
const geometry = new THREE.BoxGeometry(1, 1, 1);
const material = new THREE.MeshBasicMaterial({ color: 0x0000ff });
const helicopterTargetMesh = new THREE.Mesh(geometry, material);
helicopterTargetMesh.position.copy(helicopterTargetPosition);
helicopterTargetMesh.name = 'Helicopter Target Mesh';
scene.add(helicopterTargetMesh);

helicopter.lerpFactor = 0.005; // Adjust the value as needed for the desired speed of movement
helicopter.slerpFactor = 0.2; // Adjust the value as needed for the desired speed of movement
helicopter.shouldLookAtTargetPosition = true;
const maxAngleChange = Math.PI / 60; // Adjust as needed
function updateHelicopterLerpFactor() {
    if (helicopter.position.distanceToSquared(helicopterTargetPosition) <= 16) {
        helicopter.lerpFactor = 0;
        return;
    }

    // Slowly increase the lerp factor of the helicopter
    // Assuming you have these values
    let maxDistance = 200;
    let minLerp = 0.015;
    let maxLerp = 0.05;

    // Calculate the current distance
    let currentDistance = helicopter.position.distanceTo(helicopterTargetPosition);

    // Normalize the distance between 0 and 1
    let normalizedDistance = currentDistance / maxDistance;

    // Clamp the value to the range [0, 1]
    normalizedDistance = Math.min(Math.max(normalizedDistance, 0), 1);

    // Linearly interpolate the camera's far value
    helicopter.lerpFactor = THREE.MathUtils.lerp(minLerp, maxLerp, normalizedDistance);
}

function lerpHelicopterToTargetPosition() {
    if (!helicopter) {
        return;
    }

    // Update the camera's position using lerp
    helicopter.position.lerp(helicopterTargetPosition, helicopter.lerpFactor);

    if (helicopter.shouldLookAtTargetPosition) {
        helicopter.lookAt(helicopterTargetPosition);
    }
}

function playHelicopterAnimation() {
    if (!helicopter) {
        return;
    }

    // Sets animation to a speed simillar to movement speed
    helicopter.mixer.timeScale =
        1.25 + 0.0007 * helicopterTargetPosition.distanceTo(helicopter.position);
}

let currentPoint = 0;
let currentNode;
let lastSnapNodeIndex = 0;
let snappedDuration = 0;
function scrollToMoveHelicopter() {
    if (
        !helicopter ||
        !path ||
        path.nodes.length < 1 ||
        !helicopterTargetPosition ||
        !helicopterTargetMesh
    ) {
        return;
    }

    // if (scene.autoScroll) {
    //     // currentPoint += 0.00075;
    // } else {
    if (scene.scrollYSpeed <= 0) {
        scene.scrollDirection = 'forward';
    } else if (scene.scrollYSpeed >= 0) {
        scene.scrollDirection = 'backwards';
    }
    currentPoint -= scene.scrollYSpeed;
    // }

    // If the scroll speed slows down too much, just set it to 0
    if (scene.scrollDirection === 'forward' && scene.scrollYSpeed >= -0.00001) {
        scene.scrollYSpeed = 0;
    } else if (scene.scrollDirection === 'backwards' && scene.scrollYSpeed <= 0.00001) {
        scene.scrollYSpeed = 0;
    }

    // The point cannot be more than 1 or less than 0
    if (currentPoint >= 1) {
        currentPoint = 1;
    } else if (currentPoint <= 0) {
        currentPoint = 0;
    }

    // Node Snapping
    let nodeDistances = [];
    _.forEach(path.nodes, (node, index) => {
        const distanceToNode = helicopterTargetPosition.distanceToSquared(node.position);
        nodeDistances.push(distanceToNode);
    });
    const closestNodeDistanceSquared = _.min(nodeDistances);
    const closestNodeIndex = nodeDistances.findIndex(
        (value) => value === closestNodeDistanceSquared,
    );

    if (lastSnapNodeIndex !== closestNodeIndex && closestNodeDistanceSquared <= 16) {
        lastSnapNodeIndex = closestNodeIndex;
        currentNode = path.nodes[lastSnapNodeIndex];

        if (!currentNode.isCheckpoint) {
            return;
        }

        scene.autoScroll = false;
        scene.scrollYSpeed = 0;
        snappingToNode = true;
    }

    if (snappingToNode && helicopter.position.distanceToSquared(helicopterTargetPosition) <= 36) {
        snappedDuration += gameClock.getDelta();
    }

    if (snappedDuration >= 1.5) {
        snappingToNode = false;
        snappedDuration = 0;
    }

    helicopterTargetPosition = path.getPointAt(currentPoint);
    helicopterTargetMesh.position.copy(helicopterTargetPosition);
}

const baseHelicopterColors = {
    blue: new THREE.Color(0x2a47db),
    orange: new THREE.Color(0xff7e27),
};
const colorLerpFactor = 0.015;
let lerpAmount = 0;
let helicopterLerpSection;
function lerpHelicopterColor() {
    if (!helicopter) {
        return;
    }
    if (!helicopterLerpSection) {
        helicopterLerpSection = helicopter.getObjectByName('helicopter010');
    }

    const baseHelicopterColor =
        lerpAmount >= 1 ? baseHelicopterColors.orange : baseHelicopterColors.blue;
    helicopterLerpSection.material.color.lerp(baseHelicopterColor, colorLerpFactor);
    lerpAmount += colorLerpFactor;
    lerpAmount = lerpAmount % 2;
}

let sceneHasStarted = false;
let restartScene = false;
let initialFlyToCity = false;
let showHelicopterLeft = false;
let startingSceneGroup = new THREE.Group();
let startingSceneGroupOpacity = 0;

let titleContainer;
let titleElement;
let titleImageElement;
let subtitleElement;
createStartScene();
function createStartScene() {
    const buttonBackgroundColors = {};

    // Start Button
    let startButtonElement = document.createElement('button');
    startButtonElement.className = 'start-button';
    startButtonElement.textContent = 'Fly With Us';
    startButtonElement.addEventListener('mouseover', () => {
        startButtonElement.style.backgroundColor = '#d6d6d6';
        startButtonElement.style.cursor = 'pointer';
    });
    startButtonElement.addEventListener('mouseout', () => {
        startButtonElement.style.backgroundColor = '#ffffff';
        startButtonElement.style.cursor = 'auto';
    });
    startButtonElement.addEventListener('pointerdown', () => {
        sceneHasStarted = true;
        helicopter.shouldLerpHelicopterToTargetPosition =
            !helicopter.shouldLerpHelicopterToTargetPosition;
    });

    const buttonOffset = new THREE.Vector3(0, -4.75, 0);
    const buttonPosition = new THREE.Vector3().copy(helicopter.position).add(buttonOffset);

    let startButton = new CSS2DObject(startButtonElement);
    startButton.position.copy(buttonPosition);
    startingSceneGroup.add(startButton);

    // Metropolice Title
    titleContainer = document.createElement('div');
    titleContainer.className = 'title-container focused';

    titleElement = document.createElement('div');
    titleElement.textContent = 'METROPOLICE STUDIO';
    titleElement.className = 'title-element focused';
    titleContainer.appendChild(titleElement);

    subtitleElement = document.createElement('div');
    subtitleElement.textContent = '“We Make Video Games”';
    subtitleElement.className = 'subtitle-element focused';
    titleContainer.appendChild(subtitleElement);

    titleImageElement = new Image(64, 64);
    titleImageElement.src = '/ui/logo_metropolice.png';
    titleImageElement.className = 'title-image-element hidden';
    titleContainer.appendChild(titleImageElement);

    let mainElement = document.getElementsByTagName('main')[0];
    mainElement.appendChild(titleContainer);

    scene.add(startingSceneGroup);
}

function setStartingSceneElementsVisibility(visibility) {
    _.each(startingSceneGroup.children, (child) => {
        child.visible = visibility;
    });
}

function setStartingSceneElementsOpacity(opacityValue) {
    _.each(startingSceneGroup.children, (child) => {
        child.element.style.opacity = opacityValue + '%';
    });
}

scene.isResetting = false;
scene.resetPoint = 0;
scene.resetCurve = null;
scene.resetButtonElement = null;
scene.resetButtonElementEnabled = false;
scene.lastNode = path.nodes[path.nodes.length - 1];
resetScene();
function resetScene() {
    if (!path) {
        return;
    }

    const lastNode = scene.lastNode;
    if (lastNode.isResetNode === undefined || !lastNode.isResetNode) {
        return;
    }

    _.forEach(lastNode.container.children, (child) => {
        if (child.className !== 'reset-button') {
            return;
        }

        scene.resetButtonElement = child;
        scene.resetButtonElement.addEventListener('pointerdown', () => {
            if (scene.resetButtonElementEnabled) {
                scene.isResetting = true;
                scene.resetButtonElementEnabled = false;
            }
        });
    });

    scene.resetCurve = new THREE.CubicBezierCurve3(
        lastNode.position,
        new THREE.Vector3(110.85, 120.86, 218.439),
        new THREE.Vector3(20.85, 340.86, 420.539),
        new THREE.Vector3(-13.5, 400, 750),
    );
}

// Call the loadGui function
// const gui = await loadGui(scene);

// Send Contact Us
const contactUsSubmit = document.getElementById('contactUsSubmit');
contactUsSubmit.addEventListener('click', sendContactUsForm);
async function sendContactUsForm() {
    let form = document.getElementById('contactUsForm');

    if (!form.reportValidity()) {
        return;
    }

    let name = document.getElementById('contactUsName').value;
    let email = document.getElementById('contactUsEmail').value;
    let phone = document.getElementById('contactUsPhone').value;
    let textArea = document.getElementById('contactUsTextArea').value;

    contactUsSubmit.value = 'SUBMITTING';
    contactUsSubmit.disabled = true;

    fetch('https://email-ses-service.krakensolution.com/mail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            emailSourceAddress: 'no-reply@themetropolice.com',
            emailBodyHtml: `<div>Name: ${name}</div><div>Email : ${email}</div><div>Phone : ${phone}</div><div>Additional Information : ${textArea}</div>`,
            emailTitle: `Contact Us - ${name}`,
            emailToAddresses: ['hellhotline@themetropolice.com'],
        }),
    }).then(() => {
        contactUsSubmit.value = 'SUBMITTED!';
        contactUsSubmit.className = 'contact-us-submit success';

        setTimeout(() => {
            document.getElementById('contactUsName').value = '';
            document.getElementById('contactUsEmail').value = '';
            document.getElementById('contactUsPhone').value = '';
            document.getElementById('contactUsTextArea').value = '';

            contactUsSubmit.value = 'SUBMIT';
            contactUsSubmit.disabled = false;
            contactUsSubmit.className = 'contact-us-submit';
        }, 3000);
    });
}

// WebGL Check
if (WebGL.isWebGLAvailable()) {
    // Initiate function or other initializations here
    document.body.appendChild(renderer.domElement);
    document.body.appendChild(labelRenderer.domElement);
    animate();
} else {
    const warning = WebGL.getWebGLErrorMessage();
    document.body.appendChild(warning);
}

// Render Loop
function animate() {
    requestAnimationFrame(animate);
    playHelicopterAnimation();
    lerpHelicopterColor();

    if (camera.shouldLerpCameraToHelicopter) {
        lerpCameraToHelicopter();
    }

    if (helicopter.shouldLerpHelicopterToTargetPosition) {
        lerpHelicopterToTargetPosition();
    }

    if (scene.shouldScrollToMoveHelicopter) {
        updateHelicopterLerpFactor();
        scrollToMoveHelicopter();
    }

    // Update the animation mixer for the helicopter model
    if (helicopter && helicopter.mixer) {
        helicopter.mixer.update(animationClock.getDelta()); // Assuming you have a clock variable defined
    }

    if (path && helicopter) {
        path.updateNodesLabelVisibility(helicopter.position);
        path.updateNodesScroller(helicopter.position);
    }

    // Move the helicopter fron the starting location to the first node
    if (sceneHasStarted) {
        if (!initialFlyToCity) {
            camera.lerpFactor = 0.1;
            camera.idealLookAt = new THREE.Vector3(0, -2, 3);

            initialFlyToCity = true;

            setStartingSceneElementsVisibility(false);
            setStartingSceneElementsOpacity(0);
        }

        // The far property of the camera cannot be too high before it reaches the first node or else user will see outside of the city
        if (camera.far <= 270) {
            // Assuming you have these values
            let maxDistance = 1000;
            let minFar = 50;
            let maxFar = 280;

            // Calculate the current distance
            let currentDistance = helicopter.position.distanceTo(path.nodes[0].position);

            // Normalize the distance between 0 and 1
            let normalizedDistance = currentDistance / maxDistance;

            // Clamp the value to the range [0, 1]
            normalizedDistance = Math.min(Math.max(normalizedDistance, 0), 1);

            // Linearly interpolate the camera's far value
            camera.far = THREE.MathUtils.lerp(maxFar, minFar, normalizedDistance);
            camera.updateProjectionMatrix(); // Important to update the camera
        }

        if (camera.far <= 5800 && helicopter.position.distanceTo(path.nodes[0].position) < 75) {
            // Linearly interpolate the camera's far value
            camera.far = THREE.MathUtils.lerp(camera.far, 6000, 0.00175);
            camera.updateProjectionMatrix(); // Important to update the camera
        }

        if (helicopter.position.distanceTo(path.nodes[1].position) < 75) {
            if (camera.lerpFactor === 0.1 && !showHelicopterLeft) {
                camera.lerpFactor = 0.0005;
                showHelicopterLeft = true;
            }
            if (showHelicopterLeft && camera.lerpFactor >= 0.0005 && camera.lerpFactor <= 0.8) {
                camera.lerpFactor += 0.0002;
            }

            camera.idealOffset.copy(helicopter.cameraAngleOffsets.leftOffset);
        }

        // Shows scroller gif if  no scrolling for more than 3 seconds
        if (scene.scrollYSpeed === 0) {
            scene.noScrollDuration += gameClock.getDelta();
        } else {
            scene.noScrollDuration = 0;
        }

        if (!scene.isMobile && scene.noScrollDuration >= 3) {
            scene.scrollerGif.className = 'scroller-gif active';
        } else {
            scene.scrollerGif.className = 'scroller-gif';
        }

        if (scene.isMobile) {
            // Adjust the width and height
            titleImageElement.width = 48;
            titleImageElement.height = 48;

            titleElement.className = 'title-element hidden';
            titleImageElement.className = 'title-image-element focused';
        } else {
            titleElement.className = 'title-element';
            titleImageElement.className = 'title-image-element focused';
        }

        titleContainer.className = 'title-container';
        subtitleElement.className = 'subtitle-element';
        scene.dotsContainer.className = 'dots-container active';
    } else {
        titleContainer.className = 'title-container focused';
        titleElement.className = 'title-element focused';
        titleImageElement.className = 'title-image-element hidden';
        subtitleElement.className = 'subtitle-element focused';
        scene.dotsContainer.className = 'dots-container';
    }

    if (orbitCamera && orbitCamera.enabled && helicopter) {
        orbitCamera.target.copy(helicopter.position);
        orbitCamera.update();
    }

    if (scene.hideDebugTools && path) {
        path.show();
    } else {
        path.hide();
    }
    path.updateVisibility();
    helicopterTargetMesh.visible = scene.hideDebugTools;
    // gui.show(scene.hideDebugTools);

    // Disable reset button
    if (
        scene.lastNode &&
        helicopterTargetPosition.distanceToSquared(scene.lastNode.position) <= 25
    ) {
        scene.resetButtonElement.style.display = 'block';
        scene.resetButtonElementEnabled = true;
    } else {
        scene.resetButtonElement.style.display = 'none';
        scene.resetButtonElementEnabled = false;
    }

    if (scene.isResetting) {
        helicopter.lerpFactor = 0.0085;

        camera.lerpFactor = 0.01;

        scene.scrollYSpeed = 0;
        scene.resetPoint += 0.0085;
        scene.shouldScrollToMoveHelicopter = false;
        helicopterTargetPosition = scene.resetCurve.getPointAt(scene.resetPoint);
        setStartingSceneElementsVisibility(true);

        if (scene.resetPoint >= 1) {
            helicopter.scale.set(0.01, 0.01, 0.01);
            helicopter.rotation.set(0, THREE.MathUtils.degToRad(180), 0);
            helicopter.position.set(-13.5, 400, 750);

            camera.lerpFactor = 0.1;
            camera.idealOffset.copy(helicopter.cameraAngleOffsets.bottomOffset);
            camera.idealLookAt = new THREE.Vector3(0, -1, 0);
            camera.far = 270;

            sceneHasStarted = false;
            helicopter.shouldLerpHelicopterToTargetPosition = false;
            helicopterTargetPosition = path.getPointAt(currentPoint);
            scene.shouldScrollToMoveHelicopter = true;

            // Slowly fade in start scene
            if (startingSceneGroupOpacity <= 100) {
                startingSceneGroupOpacity += 1.5;
                setStartingSceneElementsOpacity(startingSceneGroupOpacity);
            }

            if (startingSceneGroupOpacity >= 100) {
                startingSceneGroupOpacity = 100;
                scene.isResetting = false;
                scene.resetPoint = 0;
                currentPoint = 0;
            }
        }
    }

    // Audio Visualizer
    audio.updateAudioComponent();

    renderer.render(scene, camera);
    labelRenderer.render(scene, camera);

    if (scene.isMobile) {
        scene.resetButtonElement.style.width = '50%';
        scene.resetButtonElement.style.padding = '12px 10px';
        scene.resetButtonElement.style.fontSize = '12px';
        scene.resetButtonElement.style.transform = 'translate(50%, 0)';
    }
}
