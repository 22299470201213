// Import necessary dependencies
import * as THREE from 'three';
import { FBXLoader } from 'three/addons/loaders/FBXLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';

// Function to load models
export async function loadModels() {
    let assetsPath = {
        // helicopter: '/helicopter/helicopter.fbx',
        helicopter: '/helicopter/Helicopter Anim_With Color.fbx',
    };

    const fbxLoader = new FBXLoader();
    const gltfLoader = new GLTFLoader();
    const loadedModels = {};
    const loadedMaterials = {};

    // Load FBX models
    for (const [key, assetPath] of Object.entries(assetsPath)) {
        if (!assetPath.endsWith('.fbx')) continue;

        try {
            const object = await new Promise((resolve) => {
                fbxLoader.load(
                    assetPath,
                    (loadedObject) => {
                        // Load the Alpha Map
                        // const textureLoader = new THREE.TextureLoader();
                        // const alphaMap = textureLoader.load('models/cloud in the sky/cloud_3.mtl');

                        // // Create the Material with Alpha Map
                        // const cloudMaterial = new THREE.MeshStandardMaterial({
                        //     map: loadedMaterials.materials.cloud_01.map, // Replace 'CloudMaterial' with the actual material name in your MTL
                        //     alphaMap: alphaMap,
                        //     transparent: true,
                        // });

                        // // Apply the Material to the Cloud Object
                        // cloudObject.traverse((child) => {
                        //     if (child.isMesh) {
                        //         child.material = cloudMaterial;
                        //     }
                        // });

                        // Your setup logic for FBX models
                        loadedObject.scale.set(1, 1, 1);
                        loadedObject.position.set(0, 0, 0);

                        resolve(loadedObject);
                    },
                    undefined,
                    (error) => {
                        console.error(error);
                        resolve(null);
                    },
                );
            });

            loadedModels[key] = object;
        } catch (error) {
            console.error(error);
        }
    }

    // Load GLB models
    for (const [key, assetPath] of Object.entries(assetsPath)) {
        if (!assetPath.endsWith('.glb')) continue;

        try {
            const object = await new Promise((resolve) => {
                gltfLoader.load(
                    assetPath,
                    (loadedObject) => {
                        // Your setup logic for GLB models
                        loadedObject.scene.scale.set(1, 1, 1);
                        loadedObject.scene.position.set(0, 0, 0);
                        resolve(loadedObject.scene);
                    },
                    undefined,
                    (error) => {
                        console.error(error);
                        resolve(null);
                    },
                );
            });

            loadedModels[key] = object;
        } catch (error) {
            console.error(error);
        }
    }

    // Load OBJ and MTL models
    for (const [key, assetPath] of Object.entries(assetsPath)) {
        if (!assetPath.endsWith('.json')) continue;

        // const objPath = assetPath.obj;
        // const mtlPath = assetPath.mtl;

        try {
            // console.log('objPath: ' + objPath);
            // console.log('mtlPath: ' + mtlPath);
            // const object = await new Promise((resolve) => {
            //     new MTLLoader().load(mtlPath, (loadedMaterials) => {
            //         loadedMaterials.preload();

            //         new OBJLoader().setMaterials(loadedMaterials).load(objPath, (loadedObject) => {
            //             // Your setup logic for OBJ models
            //             loadedObject.scale.set(1, 1, 1);
            //             loadedObject.position.set(0, 0, 0);
            //             resolve(loadedObject);
            //         });
            //     });
            // });

            const object = await new Promise((resolve) => {
                new OBJLoader().load(assetsPath, (loadedObject) => {
                    // Your setup logic for OBJ models
                    console.log(loadedObject);

                    resolve(loadedObject);
                });
            });

            loadedModels[key] = object;
            loadedMaterials[key] = object.material;
        } catch (error) {
            console.error(error);
        }
    }

    // Return both loaded models and materials
    return { loadedModels, loadedMaterials };
}

export default loadModels;
