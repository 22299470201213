// /component/city/setup.js
import * as THREE from 'three';
import { Path } from './Path';

export async function setup(scene, loadedItems) {
    let path = new Path(scene);
    path.line.name = 'Path Line';
    scene.add(path.line);
    loadedItems['path'] = path;
    return path;
}

export default setup;
