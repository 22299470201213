// Loops through each component directory and imports the import script
export async function setup(scene, loadedItems) {
    const componentDir = import.meta.glob('./**/setup.js', { eager: true });

    for (const setupPath in componentDir) {
        const componentName = setupPath.replace(/^\.\/(.+)\/setup\.js$/, '$1');

        try {
            const setupModule = componentDir[setupPath];
            // if (setupPath === './helicopter/setup.js') continue;
            // if (setupPath === './city/setup.js') continue;

            if (setupModule.setup && typeof setupModule.setup === 'function') {
                const componentSetup = await setupModule.setup(scene, loadedItems);
                // loadedModels[componentName] = componentSetup;
            }
        } catch (error) {
            console.error(`Error setting up ${componentName}:`, error);
        }
    }

    return loadedItems;
}

export default setup;
